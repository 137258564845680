.first {
  height: 26rem;
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    height: 37rem;
    bottom: 0;
  }

  & > div {
    position: relative;
    background: linear-gradient(90deg, rgba(255, 76, 5, 0.8) 50%, rgba(255, 255, 255, 0.9) 50%);
    height: 100%;

    & > div {
      margin: 0 auto;
      max-width: 1440px;
      display: flex;
      height: 100%;

      & > div {
        width: 50%;
        padding: 3rem;
        height: 100%;

        & > .process {
          border: var(--color-orange) solid 0.6rem;
          background-color: var(--color-white);
          border-radius: 0.8rem;
          padding: 1rem 2rem 1rem 2rem;
          width: fit-content;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          margin: 0 auto;

          & > h2 {
            font-size: var(--font-extra-big);
            font-family: MontserratBold, serif;
            text-align: center;
          }

          & > .cursor {
            position: absolute;
            width: 5rem;
            right: -2rem;
          }
        }
      }

      & > .text > p {
        font-size: var(--font-big);
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
}


.second {
  height: 45rem;
  padding: 4rem 0 1rem 0;

  & > h2 {
    font-size: var(--font-extra-big);
    font-family: MontserratBold, serif;
    text-align: center;
  }

  & > div {
    max-width: 1440px;
    width: fit-content;
    margin: 0 auto;
    display: flex;

    & > div {
      width: calc(33% - 2rem);
      margin: 1.5rem;
      height: 31rem;
      box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.4);

      & > div {
        padding: 0.8rem;

        & > p {
          text-align: center;
          font-family: MontserratBold, serif;
        }

        & > .title {
          margin-top: 8rem;
          font-size: var(--font-extra-big);
          margin-bottom: 1rem;
        }

        & > .text {
          font-size: 1.4rem;
        }
      }
    }

    & > .noImage {
      border: #ff7e4b solid 0.3rem;
      border-radius: 0.4rem;
      position: relative;
      text-align: center;

      & > div > img {
        width: 13rem;
      }

      & > .cursor {
        position: absolute;
        width: 5rem;
        bottom: -3rem;
        right: 1rem;
      }
    }

    .image {
      position: relative;
      overflow: hidden;

      & > img {
        position: absolute;
        height: 100%;
        margin-left: -22rem;
      }

      & > div {
        padding: 0.8rem;
        position: relative;
        height: 100%;
        background: linear-gradient(90deg, var(--color-yellow) 30%, var(--color-orange) 100%);
        opacity: 0.8;

        & > p {
          color: var(--color-white)
        }
      }
    }
  }
}

.third {
  height: 18rem;
  background: linear-gradient(90deg, var(--color-yellow) 30%, var(--color-orange) 100%);

  & > .top {
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 1rem;
  }

  & > h2 {
    font-size: var(--font-extra-big);
    font-family: MontserratBold, serif;
    text-align: center;
    color: var(--color-white);
  }

  & > .content {
    display: flex;
    max-width: 1440px;
    margin: 1rem auto;
    align-items: center;

    & > .card {
      width: 33%;
      height: 12rem;
      margin: 0 4rem 0 4rem;
      position: relative;
      border-radius: 0.2rem;
      background-color: var(--color-grey);

      & > div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        border-radius: 0.2rem;
        background-color: var(--color-white);
        padding: 3rem 1rem 2rem 1rem;
        display: flex;
        align-items: center;

        & > img {
          width: 2rem;
          top: 0;
          left: 0.5rem;
          position: absolute;
        }

        & > p {
          font-size: 1.1rem;
          text-align: center;
        }
      }

      .cursor {
        position: absolute;
        width: 3rem;
        bottom: 0.2rem;
        right: -1rem;
      }
    }

    .bar {
      background-color: var(--color-white);
      width: 0.4rem;
      height: 6rem;
    }
  }
}
