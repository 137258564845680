@font-face {
  font-family: 'MontserratRegular';
  src: url('../font/Montserrat-Regular.otf');
  font-style: normal;
}

@font-face {
  font-family: 'MontserratMedium';
  src: url('../font/Montserrat-Medium.otf');
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('../font/Montserrat-Bold.otf');
  font-style: normal;
}

html:root {
  --font-small: 0.7rem;
  --font-medium: 1rem;
  --font-big: 2rem;
  --font-extra-big: 3rem;
}
