.main {
  height: 32rem;
  position: relative;
  overflow: hidden;

  & > .top {
    background-color: rgba(236, 0, 0, 0.4);
    height: 1rem;
  }

  & > img {
    position: absolute;
    width: 100%;
    filter: contrast(150%);
  }

  & > div {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 173, 0, 0.5) 30%, rgba(255, 76, 5, 0.7) 100%);
    position: relative;

    & > div {
      max-width: 1440px;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-left: 8rem;

      & > .bar {
        position: absolute;
        left: -2rem;
        width: 0.3rem;
        top: -1rem;
        height: calc(100% + 0.5rem);
        background-color: var(--color-white);
      }

      & > div {
        margin-left: 2rem;

        & > h2 {
          color: var(--color-white);
          font-family: MontserratBold, serif;
          font-size: var(--font-big);
        }

        & form {
          margin-top: 2rem;
        }

        & p {
          color: var(--color-white);
          font-size: 1.2rem;
        }

        & input {
          font-size: 1.2rem;
          height: 2.5rem;
          width: 25rem;
          border: none;
          padding: 0 0.5rem 0 0.5rem;
        }

        & input:focus {
          outline: none;
        }

        & button {
          margin-top: 2rem;
          background-color: #424242;
          font-family: MontserratBold, serif;
          color: var(--color-white);
          border: none;
          font-size: 1.2rem;
          cursor: pointer;
          padding: 0.8rem 2.5rem 0.8rem 2.5rem;
        }
      }
    }
  }
}
