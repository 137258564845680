.main {
  position: absolute;
  top: 0.5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 6rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 1;
  padding: 1rem;
  align-items: center;

  & > .contact {
    background-color: var(--color-white);
    width: fit-content;
    height: fit-content;
    position: relative;
    padding: 1rem;
    border-radius: 0.3rem;
    box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.4);
    cursor: pointer;
    margin-right: 3rem;

    & > p {
      font-family: MontserratBold, serif;
      font-size: 18px;
    }
  }

  & > .contact:after {
    content: "";
    position: absolute;
    right: -12px;
    top: 1.2rem;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 14px solid var(--color-white);
  }

  .bar {
    background-color: var(--color-white);
    width: 0.1rem;
    height: 100%;
  }

  & > button {
    border: none;
    background: transparent;
    color: var(--color-white);
    font-size: 25px;
    margin: 0 1rem 0 1rem;
  }

  & > img {
    width: 8rem;
    position: absolute;
    right: 1rem;
    bottom: -2rem;
  }
}
