.first {
  height: 37rem;
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    height: 37rem;
  }

  & > div {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    position: relative;
    padding: 1rem 1rem 0 1rem;

    & > div {
      margin: 0 auto;
      height: 100%;
      max-width: 1440px;
      display: flex;
      align-items: center;

      & > .photo {
        width: 45%;
        padding-top: 5%;
        height: 100%;

        & > img {
          width: auto;
          height: 100%;
        }
      }

      & > .text {
        width: 55%;

        & > p, h2 {
          text-align: right;
        }

        & > h2 {
          font-size: var(--font-extra-big);
          margin-bottom: 1rem;
          font-weight: 600;
        }

        & > p {
          font-size: var(--font-big);
        }
      }
    }
  }
}

.second {
  height: 22rem;
  background: linear-gradient(90deg, var(--color-yellow) 30%, var(--color-orange) 100%);
  padding: 1rem 0 1rem 0;

  & > h2 {
    font-size: var(--font-extra-big);
    font-weight: 500;
    text-align: center;
    color: var(--color-white);
  }

  & > div {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;

    & > div {
      width: 50%;
      height: 100%;
      padding: 0 8rem 0 8rem;
      text-align: center;

      & > img {
        width: 20rem;
      }

      & > p {
        font-family: 'MontserratBold', serif;
        font-size: var(--font-medium);
        color: var(--color-white);
      }
    }
  }
}
