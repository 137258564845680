.main {
  height: 28rem;

  & > img {
    position: absolute;
    width: 100%;
    bottom: -2rem;
  }

  & > div {
    height: 100%;
    width: 100%;
    padding: 1rem;
    background: linear-gradient(90deg, rgba(255, 76, 5, 0.7) 0%, rgba(255, 173, 0, 0.5) 30%, rgba(255, 76, 5, 0.7) 200%);
    position: relative;

    & > div {
      margin: 0 auto;
      height: 100%;
      max-width: 1440px;

      & > div {
        width: 50%;
        position: relative;
        top: 55%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        & > h1 {
          color: var(--color-white);
          font-family: MontserratBold, serif;
          font-size: var(--font-extra-big);
        }

        & > h2 {
          color: var(--color-white);
          font-size: 26px;
        }

        & > div {
          margin-top: 1rem;
          background-color: var(--color-white);
          width: fit-content;
          padding: 1rem;
          border-radius: 0.3rem;
          box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.4);
          cursor: pointer;

          & > p {
            font-family: MontserratBold, serif;
            font-size: 18px;
          }
        }
      }
    }
  }
}
