body {
  margin: 0;
  min-width: 1200px;
}

* {
  font-family: MontserratRegular, serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-size: var(--font-medium);
}

h2 {
  font-family: MontserratMedium, serif;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 2px 20px rgb(228 228 228 / 50%);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 0 20px rgb(138 138 138 / 60%);
}
