.main {
  height: 15rem;
  padding-left: 4rem;
  position: relative;
  display: table;

  & > div {
    width: calc(100% / 3);
    display: table-cell;
    vertical-align: middle;

    & > div {
      display: flex;
      margin: 1rem;

      .circle {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background: linear-gradient(90deg, var(--color-yellow) 30%, var(--color-orange) 100%);
        margin-right: 0.5rem;
      }

      & > span {
        font-size: 1.2rem;
      }
    }

    & > p {
      font-size: 1.2rem;
    }
  }

  & > img {
    position: absolute;
    width: 12rem;
    right: 2rem;
    top: 2rem;
  }
}


